import React, { useState, useEffect } from 'react';
import { useQuery } from 'utils/react-query';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import get from 'lodash/get';

import Typography from 'components/uiLibrary/Typography';
import CommonTrans from 'components/Globals/CommonTrans';
import { useTranslation, Router } from 'src/i18n';
import { TP } from 'constants/index';
import route from 'constants/routes';

import queries from 'containers/Globals/queries';

import Image from 'components/uiLibrary/Image';
import usePageContext from 'utils/hooks/usePageContext';
import styles from './SearchBg.module.scss';

const SearchBg = () => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { data } = useQuery(queries.getHomeNumbers());
  const { baseRoute } = usePageContext();

  const staticInfo = [
    { number: get(data, 'data.performances.total'), info: 'operabase.general.m_PERF' },
    { number: get(data, 'data.companies.total'), info: 'operabase.general.m_OPCOS' },
    { number: get(data, 'data.festivals.total'), info: 'operabase.general.m_FESTIVALS' },
    { number: get(data, 'data.artists.total'), info: 'operabase.general.m_ARTISTS' },
    { number: get(data, 'data.managers.total'), info: 'operabase.general.m_MANAGERS' },
  ];

  const handleButton = () => {
    Router.push({
      pathname: route.DETAILS_ARTISTS,
    });
  };

  const banners = [
    'https://public.operabase.com/images/banners/home_banner_1_20241022T121504084Z.png',
    'https://public.operabase.com/images/banners/home_banner_2_20241022T121522538Z.png',
    'https://public.operabase.com/images/banners/home_banner_3_20241022T121539921Z.png',
  ];

  const [randomBanner, setRandomBanner] = useState(null);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * banners.length);
    setRandomBanner(banners[randomIndex]);
  }, []);

  return (
    <div className={styles.searchBg}>
      {!baseRoute ? (
        <Image
          src="https://images.operabase.com/image/fetch/w_3840/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp"
          srcSet={[
            {
              path:
                'https://images.operabase.com/image/fetch/f_avif,w_640/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp',
              type: 'image/avif',
              width: 640,
            },
            {
              path:
                'https://images.operabase.com/image/fetch/f_avif,w_750/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp',
              type: 'image/avif',
              width: 750,
            },
            {
              path:
                'https://images.operabase.com/image/fetch/f_avif,w_828/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp',
              type: 'image/avif',
              width: 828,
            },
            {
              path:
                'https://images.operabase.com/image/fetch/f_avif,w_1080/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp',
              type: 'image/avif',
              width: 1080,
            },
            {
              path:
                'https://images.operabase.com/image/fetch/f_avif,w_1200/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp',
              type: 'image/avif',
              width: 1200,
            },
            {
              path:
                'https://images.operabase.com/image/fetch/f_avif,w_1280/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp',
              type: 'image/avif',
            },
            {
              path:
                'https://images.operabase.com/image/fetch/f_webp,w_640/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp',
              type: 'image/webp',
              width: 640,
            },
            {
              path:
                'https://images.operabase.com/image/fetch/f_webp,w_750/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp',
              type: 'image/webp',
              width: 750,
            },
            {
              path:
                'https://images.operabase.com/image/fetch/f_webp,w_828/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp',
              type: 'image/webp',
              width: 828,
            },
            {
              path:
                'https://images.operabase.com/image/fetch/f_webp,w_1080/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp',
              type: 'image/webp',
              width: 1080,
            },
            {
              path:
                'https://images.operabase.com/image/fetch/f_webp,w_1200/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp',
              type: 'image/webp',
              width: 1200,
            },
            {
              path:
                'https://images.operabase.com/image/fetch/f_webp,w_1280/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp',
              type: 'image/webp',
            },
          ]}
          alt="Operabase Banner"
          lazy={false}
          disableCloudinary
          disableNextImage
          preload
          className={styles.bg}
        />
      ) : (
        <Image src={randomBanner} className={styles.bg} preload lazy={false} alt="Operabase Banner" disableCloudinary />
      )}
      <div className={styles.mainContent}>
        <Typography
          className={styles.mainHeading}
          variant={!baseRoute ? 'h1' : 'p'}
          color="white"
          size={40}
          secondaryFont
        >
          <CommonTrans i18nKey={`${TP}.FN_HOMEPAGE_BANNER_H1`} ns="NS_APP_GLOBALS" />
        </Typography>
        <Typography className={styles.subHeading} variant="p" color="white" size={18}>
          {t(`${TP}.FN_HOMEPAGE_BANNER_GENRE`)}
        </Typography>
        {data && (
          <div className={styles.stats}>
            {staticInfo.map(item => (
              <div className={styles.statItem} key={item.info}>
                {item.number && item.number.toLocaleString()} <span className={styles.statInfo}>{t(item.info)}</span>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={styles.newContent}>
        <div className={styles.header}>{t(`${TP}.PRO_PAGE_INDUSTRY`)} </div>
        <div className={styles.content}>
          <CommonTrans
            i18nKey={`${TP}.PRO_TOOLS`}
            ns="NS_APP_GLOBALS"
            components={{
              pro: <span className={styles.pro} />,
            }}
          />
        </div>
        <button className={styles.button} onClick={handleButton} type="button">
          {t(`${TP}.FN_LEARNMORE`)} <ArrowForwardIosIcon className={styles.icon} />
        </button>
      </div>
    </div>
  );
};

export default SearchBg;
